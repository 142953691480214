import React, { useState, useEffect } from "react";
import styles from "./docs.module.css";
import axios from "axios";
import Inschrijving from "./inschrijving";
import Card from "./card";
import Info from "./info";
import format from "xml-formatter";
import { useSearchParams } from "react-router-dom";

export default function Docs() {
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedTestUittreksel =
    searchParams.get("testuittreksel") ||
    "90000003-persoon-is-alleen-zelfstandig-bevoegd-voor-een-bv";

  const [bevoegdheidResponse, setBevoegdheidResponse] = useState();
  const [defaultHighlight, setDefaultHighlight] = useState();
  const [highlight, setHighlight] = useState();
  const [testUittreksels, setTestUittreksels] = useState([]);
  const [currentTab, setCurrentTab] = useState("info");
  const [error, setError] = useState();

  useEffect(() => {
    async function getTestUitreksels() {
      // try {
      //   let { data } = await axios({
      //     url: `/api/test-inschrijvingen`,
      //     method: 'get',
      //   });
      //   setTestUittreksels(['90000000-inschrijving-niet-gevonden', ...data]);
      // } catch (error) {
      //   console.log(error);
      // }

      setTestUittreksels([
        "90000000-inschrijving-niet-gevonden",
        "90000001-persoon-komt-niet-voor-op-inschrijving",
        "90000002-persoon-is-eigenaar-van-eenmanszaak",
        "90000003-persoon-is-alleen-zelfstandig-bevoegd-voor-een-bv",
        "90000004-persoon-is-onbeperkt-bevoegd-voor-een-failliet-vof",
        "90000005-persoon-is-gezamenlijk-bevoegd-voor-een-stichting",
        "90000006-persoon-heeft-een-beperkte-volmacht-voor-een-stichting",
        // "90000014",
        // "90000016",
        // "90000017",
        // "90000018",
        // "90000019",
        // "90000021",
        // "90000072",
        // "90000129",
        // "90000501",
        // "90000502",
        // "90003233",
      ]);
    }
    getTestUitreksels();
  }, []);

  useEffect(() => {
    if (testUittreksels.length === 0) return;
    
      if (!selectedTestUittreksel) return setBevoegdheidResponse(null);
      let kvkNummer = selectedTestUittreksel.split("-")[0];
      let filePath = (`/cache-inschrijvingen/${kvkNummer}.json`);

      fetch(filePath)
        .then((res) => res.json())
        .then((text) => {
          setBevoegdheidResponse(text);
          setDefaultHighlight(null);
        })
        .catch((error) => {
          setBevoegdheidResponse(null);
          setError(error.message);
        });

  }, [testUittreksels, selectedTestUittreksel]);

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.tabs}>
              <div
                className={
                  styles.tab +
                  " " +
                  (currentTab === "info" ? styles.tabSelected : "")
                }
                onClick={() => setCurrentTab("info")}
              >
                Info
              </div>
              <div
                className={
                  styles.tab +
                  " " +
                  (currentTab === "inschrijving" ? styles.tabSelected : "")
                }
                onClick={() => setCurrentTab("inschrijving")}
              >
                KVK inschrijving
              </div>
              <div
                className={
                  styles.tab +
                  " " +
                  (currentTab === "xml" ? styles.tabSelected : "")
                }
                onClick={() => setCurrentTab("xml")}
              >
                Originele XML
              </div>
            </div>
            {currentTab === "inschrijving" && (
              <div className={styles.inschrijvingContainer}>
                <Inschrijving
                  inschrijving={
                    bevoegdheidResponse && bevoegdheidResponse.inschrijving
                  }
                  highlight={highlight}
                />
              </div>
            )}
            {currentTab === "info" && (
              <div className={styles.infoContainer}>
                <Info highlight={highlight} />
              </div>
            )}
            {currentTab === "xml" && (
              <div className={styles.infoContainer}>
                <div className={styles.xml}>
                  {format(bevoegdheidResponse.inschrijvingXML)}
                </div>
              </div>
            )}
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.selectContainer}>
              <select
                id="inschrijvingen"
                value={selectedTestUittreksel}
                onChange={(e) =>
                  setSearchParams({ testuittreksel: e.target.value })
                }
              >
                {testUittreksels.map((testUittreksel) => (
                  <option key={testUittreksel} value={testUittreksel}>
                    {testUittreksel}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.jsonContainer}>
              {bevoegdheidResponse ? (
                <Card
                  bevoegdheidUittreksel={
                    bevoegdheidResponse.bevoegdheidUittreksel
                  }
                  highlight={highlight}
                  setHighlight={setHighlight}
                  defaultHighlight={defaultHighlight}
                  setDefaultHighlight={setDefaultHighlight}
                  paths={bevoegdheidResponse.paths}
                  tab={currentTab}
                />
              ) : (
                <div style={{ padding: "12px" }}>{error}</div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.path}>
          <div>{highlight ? highlight.replaceAll(".", "/") : ""}</div>
        </div>
      </div>
    </div>
  );
}
